<template>
<!--  <van-list-->
<!--      class="ui-van-page is-light"-->
<!--      v-model="loading"-->
<!--      :finished="finished"-->
<!--      finished-text=""-->
<!--      @load="onLoad"-->
<!--  >-->
   <div>
     <div class="home-search-bar">
       <div class="home-search-bar__inner">
         <div class="home-search-bar__area">
           <div @click="getSelectBranchArea">
             <span>{{areaName}}</span>
             <van-icon name="arrow-down"/>
           </div>
         </div>
         <van-search
             class="flex-item"
             v-model="keyword"
             shape="round"
             placeholder="搜一搜"
             background="transparent"
             @search="onSearch"
             @cancel="onCancel"
         />
         <div class="home-search-bar__scan">
           <van-icon name="scan"/>
         </div>
       </div>
     </div>
     <div class="bg-gray" :style="{'height':(tabHeight-44)+'px'}">
       <div class="station-item" @click="toShopList(item.branchId)" v-for="item in dataList" :key="item.branchId">
         <div class="station-header">
           <div class="station-img">
             <van-image :src="item.logo"></van-image>
           </div>
           <div class="station-location">
             <p class="station-name">{{item.branchName}}</p>
             <p class="station-username">{{item.contactName}}</p>
             <p class="station-remark">{{item.simpleDesc}}</p>
             <p class="station-distance">距离：<span class="dis-value">{{item.disValueStr}}</span></p>
           </div>
         </div>
         <div class="station-info">
           <div class="station-phone">
             <van-icon name="phone-o" size="18"/><span>{{item.contactMobile}}</span>
           </div>
           <div class="station-address">
             <van-icon name="location-o" size="18"/><span>{{item.provinceName+item.city+item.area+item.address}}</span>
           </div>
         </div>
         <div class="station-button">
           <!--          <van-button round plain type="info" color="red">拨号</van-button>-->
           <!--          <van-button round plain type="info" color="#8fc42e">导航</van-button>-->
         </div>
       </div>
     </div>
     <!--选择地区-->
     <van-popup v-model="showPopup" round position="bottom">
       <van-cascader
           v-model="cascaderValue"
           title="选择所在地区"
           :options="options"
           active-color="#8fc42e"
           @close="showPopup = false"
           @finish="onFinish"
       />
     </van-popup>
   </div>
<!--  </van-list>-->
</template>

<script>
import wx from 'weixin-jsapi'
export default {
  name: "Station",
  data() {
    return {
      keyword: '',
      loading: false,
      finished: false,
      pageNo: 0,
      pageSize: 100,
      dataList: [],
      tabHeight: 0,
      areaName:'暂无定位',
      branchName:'',
      areaCode:0,
      branchId:0,
      showPopup:false,
      options:[],
      cascaderValue:'',
      location:{
        latitude:'',
        longitude:''
      },
      branchType:6//护理站
    }
  },
  created() {
    this.init();
    this.onLoad();
  },
  mounted() {
    this.setHeight()
    window.addEventListener('resize', this.setHeight)
  },
  methods: {
    async init(){
      //this.$showLoading();
      let location = this.$store.getters.getLocation;
      // if(!location){
      //   location = await this.getLocation();//定位
      // }
      console.log('location',location);
      //this.$hideLoading();
      if(location){
        this.areaName = location.areaName;
        this.areaCode = location.areaCode;
        this.branchId = location.branchId;
        this.branchName = location.branchName;
        //console.log('setLocation',location);
      }
      this.updateSelectBranch()
    },
    updateSelectBranch(){
      this.$store.commit("setLocation",{
        areaName:this.areaName,
        areaCode:this.areaCode,
        branchId:this.branchId,
        branchName:this.branchName
      });
    },
    onSearch() {
      console.log(12)
    },
    onCancel() {
      console.log(233)
    },
    reload() {
      this.pageNo = 1
    },
    async onLoad() {
      this.pageNo++
      if(this.pageNo === 1){
        await this.getLocation();
      }
      this.getList()
    },
    setHeight() {
      this.tabHeight = window.innerHeight - 50 -20;
    },
    getList(){
      this.$axios.get('/api/wxmp/branch/list',{
        params:{
          limit:this.pageSize,
          page:this.pageNo,
          deptId:this.$store.state.deptId,
          branchType:this.branchType,
          areaCode:this.$store.state.location.areaCode,
          searchName:this.keyword,
          latitude:this.location.latitude,
          longitude:this.location.longitude,
        }
      }).then((res) =>{
        console.log('res',res);
        this.dataList = res.data.list;
        if(res.data.totalPage <= this.pageNo){
          this.finished = true;
        }
        this.loading = false;
      }).catch((err) =>{
        console.log('err',err);
      })
    },
    getLocation(){
      let that = this;
      return new Promise((resolve,reject) =>{
        that.$axios.postForm('/api/wxmp/public/wxJSConfig',{
          appId:that.$store.state.appId,
          localUrl:window.location.href.split('#')[0]
        }).then((res) =>{
          //alert('获取微信js-api配置信息成功'+JSON.stringify(res));
          let config = res.configJson;
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId:config.appId, // 必填，公众号的唯一标识
            timestamp:config.timestamp , // 必填，生成签名的时间戳
            nonceStr: config.nonceStr, // 必填，生成签名的随机串
            signature: config.signature,// 必填，签名s
            jsApiList: ['getLocation'] // 必填，需要使用的JS接口列表
          });
          wx.ready(() =>{
            wx.getLocation({
              type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: function (res) {
                let latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                let longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                //console.log('地理位置',JSON.stringify(res));
                if(latitude && longitude){
                  that.location = {
                    latitude:latitude,
                    longitude:longitude
                  }
                  resolve();
                }
              }
            });
          })
        }).catch((err) =>{
          alert('获取微信js-api配置信息失败'+JSON.stringify(err));
          reject()
        })
      })
    },
    onFinish(selectedObj){//选择地区后执行
      this.areaName = selectedObj.selectedOptions[2].text;
      this.areaCode = selectedObj.selectedOptions[2].value;
      this.branchId = selectedObj.selectedOptions[2].branchId;
      this.branchName = selectedObj.selectedOptions[2].branchName;
      //console.log( this.areaName, this.areaCode, this.branchId, this.branchName);
      this.updateSelectBranch();
      //this.getClassList(this.branchId);
      this.pageNo = 1;
      this.getList();
      this.showPopup = false;
    },

    getSelectBranchArea(){
      if(this.options.length){
        this.showPopup = true;
        return;
      }
      this.cascaderValue = this.$store.getters.getLocation.areaCode;
      this.$axios.get('/api/wxmp/public/selectBranchArea',{
        params:{
          deptId:this.$store.state.deptId,
          branchType:this.branchType
        }
      }).then((res) =>{
        this.options = res.optionArray;
        this.showPopup = true;
      }).catch((err) =>{
        console.log('err',err);
      })
    },
    toShopList(branchId){
      this.$router.push({name:'shopList',query:{
          branchId:branchId
        }
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setHeight)
  }
}
</script>

<style lang="scss" scoped>

.van-sidebar-item--select{
  color: $--color-primary;
}
.van-sidebar-item--select::before{
  background-color:$--color-primary;
}
.home-search-bar__inner{
  background-color: $--color-primary;
}
.shopping-card-abs{
  background-color: $--color-primary;
}
.bg-gray{
  background-color: #f5f5f5;
}
.station-item{
  background-color: #fff;
  width: 95%;
  margin: 0 auto;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
}
.station-header{
  display: flex;
  border-bottom: 1px solid #f5f5f5;
}
.station-img{
  padding: 10px;
}
.station-img .van-image{
  border-radius: 100%;
  width: 80px;
  height: 80px;
  overflow: hidden;
}
.station-location{
  padding-left: 10px;
}
.station-location p{
  margin: 5px 0;
  padding:0;
}
.station-location .station-name{
  color: black;
  font-size: 16px;
  font-weight: bolder;
}
.station-location .station-username{
  padding-bottom: 5px;
  color: black;
}
.station-location .station-remark{
  color: #888;
}
.station-location .station-distance{
  margin-top: 5px;
  color: #888;
}
.station-location  .dis-value{
  color: $--color-primary;
  font-size: 12px;
}
.station-info{
  margin-top: 10px;
}
.station-info div{
  padding: 5px;
  //vertical-align: middle;
}
.station-info .van-icon{
  vertical-align: middle;
  padding: 0 5px;
}
.station-button{
  margin-top: 10px;
  text-align: right;
}
.station-button .van-button{
  margin: 0 5px;
  width: 80px;
  height: 35px;
}
</style>
