<template>
  <van-list
      class="ui-van-page is-light"
      v-model="loading"
      :finished="finished"
      finished-text=""
      @load="onLoad"
  >
    <div class="home-search-bar">
      <div class="home-search-bar__inner">
        <div class="home-search-bar__area">
          <div @click="getSelectBranchArea">
            <span>{{areaName}}</span>
            <van-icon name="arrow-down"/>
          </div>
        </div>
        <van-search
            class="flex-item"
            v-model="keyword"
            shape="round"
            placeholder="搜一搜"
            background="transparent"
            @search="onSearch"
            @cancel="onCancel"
        />
        <div class="home-search-bar__scan">
          <van-icon name="scan"/>
        </div>
      </div>
    </div>

    <div class="panel1 gap-mb">
      <div class="my-swipe">
        <van-swipe :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="item of swipeList" :key="item.id">
            <img class="my-swipe-img" :src="item.src">
          </van-swipe-item>
        </van-swipe>
      </div>

<!--      <div class="flex-row flex-wrap my-navs">-->
<!--        <div class="my-navs__item" v-for="(item, index) of 10" :key="index">-->
<!--          <div class="text-center">-->
<!--            <van-image-->
<!--                width="40"-->
<!--                height="40"-->
<!--                src="http://www.gx114.cc/upload/slider/000/000/001/598dd5f9b8631842.png"-->
<!--            />-->
<!--            <div>商城</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <div class="bg-white flex-row" :style="{'height':(tabHeight-54)+'px'}" v-if="branchNone">
      <div class="goto-branch">
        <p class="none-title">当前位置<span class="goto-area-name">{{areaName}}</span>暂无机构</p>
        <p>前往&nbsp;<span class="goto-branch-name" @click="gotoDefault">佛山顺德总部机构</span>&nbsp;&nbsp;或者&nbsp;<span class="goto-branch-name" @click="getSelectBranchArea">选择其它区域</span></p>
      </div>
    </div>
    <div class="bg-white flex-row" :style="{'height':(tabHeight-54)+'px'}" v-else>
      <div class="sidebar-container">
        <van-sidebar v-model="activeKey" @change="getGoodsList" >
          <van-sidebar-item :title="item.className" v-for="(item,index) in classList" :key="index"/>
        </van-sidebar>
      </div>
      <div class="branch-goods-container">
        <van-card
            v-for="(item, index) of goodsList"
            :key="index"
            :desc="item.subTitle"
            :title="item.goodsName"
            :thumb="item.coverPic"
            @click="linkList(item.goodsId)"
        >
          <template #price>
            <div class="goods-price">
              &yen;{{item.salePrice}} <span class="origin-price">{{item.marketPrice}}</span>
            </div>
          </template>
          <template #footer>
            <div class="get-num">
              <van-stepper v-model="item.buyNum" theme="round" min="0" button-size="20" disable-input @plus="changeGoodsNum(item.goodsId,1)" @minus="changeGoodsNum(item.goodsId,0)" />
            </div>
          </template>
        </van-card>
      </div>
    </div>
    <div class="shopping-card-abs" @click="toShoppingCart">
      <van-badge :content="shoppingCartGoodsList.length">
        <van-icon name="shopping-cart-o" size="36px" color="#fff" />
      </van-badge>
    </div>
    <!--
    产品规格
    -->
    <van-sku
        v-model="showSku"
        :sku="sku"
        :goods="goodsObj"
        :goods-id="0"
        :quota="0"
        :quota-used="0"
        :hide-stock="sku.hide_stock"
        @buy-clicked="onBuyClicked"
        @add-cart="onAddCartClicked"
    />
    <!--选择地区-->
    <van-popup v-model="showPopup" round position="bottom">
      <van-cascader
          v-model="cascaderValue"
          title="选择所在地区"
          :options="options"
          active-color="#8fc42e"
          @close="showPopup = false"
          @finish="onFinish"
      />
    </van-popup>
  </van-list>
</template>

<script>
import storageUtils from "@/utils/storage";
import wx from 'weixin-jsapi'
export default {
  name: "Home",
  data() {
    return {
      keyword: '',
      swipeList: [],
      navIndex: 0,
      loading: false,
      finished: false,
      pageNo: 1,
      pageSize: 16,
      dataList: [],
      value:0,
      classList:[],
      goodsList:[],
      activeKey:0,
      tabHeight: 0,
      showSku:false,
      areaName:'暂无定位',
      branchName:'',
      areaCode:0,
      goodsObj:{
        coverPic:'',
        picture:'',
        goodsName:'',
        marketPrice:'',
        salePrice:'0',
        saledCount:'',
        subTitle:'',
        contentArray:[]
      },
      sku: {
        // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
        // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
        tree: [],
        // 所有 sku 的组合列表，比如红色、M 码为一个 sku 组合，红色、S 码为另一个组合
        list: [],
        price: '1.00', // 默认价格（单位元）
        stock_num: 1, // 商品总库存
        // collection_id: 2261, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
        none_sku: false, // 是否无规格商品
        hide_stock: false // 是否隐藏剩余库存
      },
      branchId:0,
      showPopup:false,
      options:[],
      cascaderValue:'',
      selectLocation:false,
      branchNone:false,
      branchType:4//经销商
    }
  },
  created() {
    this.init();
    //console.log('shoppingCartGoodsList',this.shoppingCartGoodsList);
  },
  mounted() {
    this.getSwipeList()
    this.setHeight()
    window.addEventListener('resize', this.setHeight)
  },
  computed:{
    shoppingCartGoodsList(){
      return this.$store.state.shoppingCartGoodsList;
    }
  },
  watch:{
    shoppingCartGoodsList(val){
      this.setBuyNum(val);
    }
  },
  methods: {
    async init(){
      //this.$showLoading();
      // let location = this.$store.getters.getLocation;
      // if(!location){
      //   location = await this.getLocation();//定位
      // }
      // console.log('location',location);
      // //this.$hideLoading();
      // if(location){
      //   this.areaName = location.areaName;
      //   this.areaCode = location.areaCode;
      //   this.branchId = location.branchId;
      //   this.branchName = location.branchName;
      //   //console.log('setLocation',location);
      // }
      // // this.branchId = this.$store.state.defaultBranchId;//默认地址
      // // this.areaName = "顺德区";
      // // this.areaCode = "440606";
      // if(this.branchId <= 0){
      //   this.branchNone = true;
      // }else{
      //   this.getClassList(this.branchId);
      // }
      // this.updateSelectBranch()
      this.gotoDefault()//
    },
    updateSelectBranch(){
      this.$store.commit("setLocation",{
        areaName:this.areaName,
        areaCode:this.areaCode,
        branchId:this.branchId,
        branchName:this.branchName
      });
    },
    setBuyNum(shoppingCartGoodsList){
      this.goodsList.forEach(goodsItem =>{
        shoppingCartGoodsList.forEach(shoppingCartItem =>{
          if(shoppingCartItem.goodsId === goodsItem.goodsId){
            goodsItem.buyNum = shoppingCartItem.buyNum;
          }
        })
      })
    },
    onSearch() {
      console.log(12)
    },
    onCancel() {
      console.log(233)
    },
    getSwipeList() {
      this.swipeList = [{
        id: 1,
        src: require('../../assets/loop0001.png'),
        link: ''
      }, {
        id: 2,
        src: require('../../assets/loop0002.png'),
        link: ''
      }]
    },
    clickNav(index) {
      this.navIndex = index;
      this.getGoodsList(this.classList[index].classId)
    },
    getList() {
      setTimeout(() => {
        let arr = []
        for(let i=1; i<17; i++) {
          arr.push({
            id: (new Date()).getTime() + i,
          })
        }
        this.dataList = this.dataList.concat(arr)
        this.loading = false
        if(this.pageNo >= 4) {
          this.finished = true
        }
      })
    },
    reload() {
      this.pageNo = 1
    },
    onLoad() {
      this.pageNo++
      this.getList()
    },
    setHeight() {
      this.tabHeight = window.innerHeight - 50 -124-12;
      console.log('window.innerHeight',window.innerHeight);
    },
    linkList(goodsId) {
      this.$router.push({name:'shopDetails',query:{goodsId:goodsId}})
    },
    getClassList(branchId){
      this.$axios.get('/api/wxmp/goods/class/list',{
        params:{
          branchId:branchId
        }
      }).then((res) =>{
        this.goodsList = [];
        //console.log('res',res);
        this.classList = res.data;
        if(this.classList.length > 0){
          this.getGoodsList(0);
        }
        //console.log('this.classList',this.classList);
      }).catch((err) =>{
        console.log('err',err);
      })
    },
    getGoodsList(index){
      //console.log('index - >',index);
      this.$axios.get('/api/wxmp/goods/list',{
        params:{
          branchId:this.branchId,
          classId:this.classList[index].classId
        }
      }).then((res) =>{
        this.goodsList = res.data;
        this.setBuyNum(this.shoppingCartGoodsList);
      }).catch((err) =>{
        console.log('err',err);
      })
    },
    changeGoodsNum(goodsId,type){
      event.stopPropagation();
      if(type){
        //console.log('增加');
        //this.$store.commit("addShoppingCartGoods",{goodsId:goodsId,buyNum:1,itemId:0});
        this.getGoodsSku(goodsId);
      }else{
        //减少
        //console.log('减少');
        this.$store.commit("subShoppingCartGoods",{goodsId:goodsId,buyNum:1,itemId:0});
      }
    },
    getGoodsSku(goodsId){
      this.$axios.get('/api/wxmp/goods/details',{
        params:{
          goodsId:goodsId
        }
      }).then((res) =>{
        this.goodsObj.goodsId = res.data.goodsId
        this.goodsObj.coverPic = res.data.coverPic
        this.goodsObj.picture = res.data.coverPic
        this.goodsObj.goodsName = res.data.goodsName
        this.goodsObj.marketPrice = res.data.marketPrice
        this.goodsObj.salePrice = res.data.salePrice
        this.goodsObj.saledCount = res.data.saledCount
        this.goodsObj.subTitle = res.data.subTitle
        this.goodsObj.contentArray = res.data.contentArray
        this.sku.price = res.data.salePrice;
        //设置规格
        let childParentObj = {}
        //规格
        let standardArray = res.data.standardArray;
        if(!standardArray || !standardArray.length){
          this.sku.none_sku = true;
        }
        let tree = [];
        for (let i = 0; i < standardArray.length;i++){
          let standardObj = standardArray[i];
          let itemArray = standardArray[i].itemArray;
          let v = [];
          for (let s = 0 ;s < itemArray.length;s++){
            let itemObj = itemArray[s];
            v.push({
              id:itemObj.standardItemId+"",
              name:itemObj.standardItemName
            })
            childParentObj[itemObj.standardItemId] = standardObj.sId;
          }
          tree.push({
            k:standardObj.standardName,
            k_s:standardObj.sId+"",
            v:v
          })
        }
        this.sku.tree = tree;
        //商品
        let list = [];
        let standardJson = res.data.standardJson;
        for (let item in standardJson) {
          let value = standardJson[item];
          let keyArrays  = item.split("$");
          let obj = {
            id:value.itemId,
            price:(value.salePrice * 100),
            stock_num:value.stockNum
          }
          keyArrays.forEach(sItem =>{
            obj[childParentObj[sItem]] = sItem;
          })
          list.push(obj)
        }
        this.sku.list = list;
        this.showSku = true;
        //this.skuCache[goodsId] = this.sku;
      }).catch((err) =>{
          console.log('err',JSON.stringify(err));
      })
    },
    onBuyClicked(skuData){//立即购买
      this.showSku = false;
      //加入localStorege
      console.log('skuData',JSON.stringify(skuData));
      storageUtils.setItem("buyGoodsList",JSON.stringify([
        {goodsId:this.goodsObj.goodsId,buyNum:skuData.selectedNum,itemId:skuData.selectedSkuComb.id}
      ]))
      this.$router.push({name:'ShopSettle'});
    },
    onAddCartClicked(skuData){
      this.$store.commit("addShoppingCartGoods",{goodsId:this.goodsObj.goodsId,buyNum:skuData.selectedNum,itemId:skuData.selectedSkuComb.id});
      this.showSku = false;
    },
    toShoppingCart(){
      this.$router.push('/shoppingCart')
    },
    getLocation(){
      let that = this;
      return new Promise((resolve,reject) =>{
        that.$axios.get('/api/wxmp/public/wxJSConfig',{
          params:{
            appId:that.$store.state.appId,
            localUrl:window.location.href
          }
        }).then((res) =>{
          //console.log('获取微信js-api配置信息成功',JSON.stringify(res));
          let config = res.configJson;
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId:config.appId, // 必填，公众号的唯一标识
            timestamp:config.timestamp , // 必填，生成签名的时间戳
            nonceStr: config.nonceStr, // 必填，生成签名的随机串
            signature: config.signature,// 必填，签名
            jsApiList: ['getLocation'] // 必填，需要使用的JS接口列表
          });
          wx.ready(() =>{
            wx.getLocation({
              type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: function (res) {
                let latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                let longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                //console.log('地理位置',JSON.stringify(res));
                if(latitude && longitude){
                  that.$axios.get('/api/wxmp/public/location',{
                    params:{
                      latitude:latitude,
                      longitude:longitude,
                      deptId:that.$store.state.deptId,
                      branchType:this.branchType
                    }
                  }).then((res) =>{
                    resolve(res);
                  }).catch((err) =>{
                    console.log('百度地图解析地理位置 err',JSON.stringify(err));
                    reject()
                  })
                }
              }
            });
          })
          // wx.error(function(res){
          //   console.log('微信验证信息失败[wx.error]');
          // });
        }).catch((err) =>{
          console.log('获取微信js-api配置信息失败',JSON.stringify(err));
          reject()
        })
      })
    },
    onFinish(selectedObj){//选择地区后执行
      this.areaName = selectedObj.selectedOptions[2].text;
      this.areaCode = selectedObj.selectedOptions[2].value;
      this.branchId = selectedObj.selectedOptions[2].branchId;
      this.branchName = selectedObj.selectedOptions[2].branchName;
      //console.log( this.areaName, this.areaCode, this.branchId, this.branchName);
      this.updateSelectBranch();
      this.getClassList(this.branchId);
      this.showPopup = false;
      this.branchNone = false;
    },
    getSelectBranchArea(){
      if(this.options.length){
        this.showPopup = true;
        return;
      }
      this.cascaderValue = this.$store.getters.getLocation.areaCode;
      this.$axios.get('/api/wxmp/public/selectBranchArea',{
        params:{
          deptId:this.$store.state.deptId,
          branchType:this.branchType
        }
      }).then((res) =>{
        this.options = res.optionArray;
        this.showPopup = true;
      }).catch((err) =>{
        console.log('err',err);
      })
    },
    gotoDefault(){
      this.branchNone = false;
      this.branchId = this.$store.state.defaultBranchId;//默认地址
      this.areaName = "顺德区";
      this.areaCode = "440606";
      this.updateSelectBranch();
      this.getClassList(this.branchId);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setHeight)
  }
}
</script>

<style lang="scss" scoped>
.my-swipe-img {
  width: 100%;
  height: 130px;
  object-fit: cover;
  border-radius: 6px;
}
.panel1 {
  background-color: #fff;
  padding: 12px;
}

.my-navs {
  display: flex;

  &__item {
    flex: 0 0 20%;
    max-width: 20%;
    margin-top: $gap-size;
  }
}

.goods-nav-scroll {
  overflow-x: auto;
}

.goods-nav {
  display: flex;

  &__title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 3px;
  }

  &__sub {
    color: #787878;
    padding: 1px 8px;
    border-radius: 8px;
    font-size: 12px;
  }

  &__item {
    white-space: nowrap;
    padding: 12px;
    text-align: center;

    &.is-active {
      color: #01c1aa;
    }

  }
}

.goods-nav__item.is-active .goods-nav__sub {
  color: #fff;
  background-color: #01c1aa;
}
.goods-price{
  color: red;
}
.get-num{
  position: absolute;
  right: 10px;
  bottom: 0px;
  transform: translateY(-50%);
}
.van-card{
  min-height: 120px;
}
.van-card__content{
  padding: 10px 0;
}
.sidebar-container{
  width: 100px;
}
.branch-goods-container{
  flex: 1;
  overflow-y:scroll;
}
.shopping-card-abs.shopping-left{
  left: 20px;
  border-bottom: 80px;
}
.van-sidebar-item--select{
  color: $--color-primary;
}
.goto-branch{
  height: 100%;
  line-height: 100%;
  width: 100%;
  text-align: center;
  color: #888;
  margin-top: 80px;
}
.goto-branch .goto-area-name{
  display: inline-block;
  padding: 0 5px;
  font-size: 16px;
  color: black;
}
.goto-branch .none-title{

}
.goto-branch .goto-branch-name{
  color: $--color-primary;
  text-decoration: underline;
}
</style>
