<template>
  <div class="news-container">
    <div class="news-header">
      <div class="news-title">{{newsObj.title}}</div>
        <div class="news-info">
          <p class="news-time">{{newsObj.time}}</p>
          <div class="show-number">
            <van-icon name="eye-o" /><span class="num">{{newsObj.watchNum}}</span>
          </div>
        </div>
    </div>
    <div class="news-content" v-html="newsObj.content">
    </div>
  </div>
</template>

<script>
export default {
  name: "HistoryDetails",
  data(){
    return {
      newsId:0,
      newsObj:{
        newsId:0,
        title:'',
        subTitle:'',
        watchNum:0,
        time:'',
        content:''
      }
    }
  },
  created() {
    this.newsId = this.$route.query.newsId;
    this.getNewsInfo();
  },
  methods: {
    getNewsInfo(){
      this.$axios.get("/api/wxmp/news/details",{
        params:{
          newsId:this.newsId
        }
      }).then((res) =>{
        console.log('res',res);
        this.newsObj = res.data;
      }).catch((err) =>{
        console.log('err',err);
      })
    }
  }
}
</script>

<style scoped>
p{
  margin: 0;
  padding: 0;
}
.news-container{
  padding: 0 5px;
  max-width: 100%;
}
.news-header{
  padding: 5px;
  border-bottom: 1px solid #f5f5f5;
  color: black;
  position: relative;
}
.news-title{
  font-size: 14px;
  font-weight: bolder;
}
.news-info{
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  color: #666;
  font-size: 12px;
}
.show-number span{
  vertical-align: middle;
  padding: 0 2px;
  font-size: 12px;
}
.van-icon{
  vertical-align: middle;
}
.news-content{
  max-width: 100%;
  margin-top: 5px;
  margin-bottom: 50px;
}

.news-container img{
  max-width: 100%;
}

</style>
