<template>
  <div class="video-wrapper">
    <div class="video-item" v-for="item in videoList" :key="item.id">
      <div class="video-img">
        <van-image :src="item.poster"></van-image>
        <div class="video-play" @click="showVideo(item.url)">
          <van-icon name="play" size="40" color="#fff" />
        </div>
      </div>
      <div class="video-title">
        {{item.title}}
      </div>
<!--      <span class="video-time">03:09</span>-->
    </div>
<!--    <div class="video-item">-->
<!--      <div class="video-img">-->
<!--        <van-image src="https://img01.yzcdn.cn/vant/apple-2.jpg"></van-image>-->
<!--        <div class="video-play" @click="showVideo">-->
<!--          <van-icon name="play" size="40" color="#fff" />-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="video-title">-->
<!--        用户使用操作说明用户使用操作还是觉得回复-->
<!--      </div>-->
<!--      <span class="video-time">03:09</span>-->
<!--    </div>-->
    <!--视频弹框-->
    <van-popup v-model="videoShow" position="bottom" :style="{ height: '100%' }" >
      <div class="video-show-wrapper">
        <div class="video-obj">
          <video
              class="video-player"
              id="player"
              preload
              autoplay
              loop
              webkit-playsinline="true"
              playsinline="true"
              x-webkit-airplay="true"
              x5-video-player-type="h5"
              x5-video-player-fullscreen="true"
              x5-video-ignore-metadata="true"
              controlslist="nodownload"
              poster=""
              :src="videoUrl"></video>
        </div>
        <div class="video-close" @click="closeVideoShow">
          <van-icon name="cross" size="30" color="#888" />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "Video",
  data(){
    return{
      videoShow:false,
      myPlayer: null,
      videoList:[],
      videoUrl:'https://txmov2.a.yximgs.com/bs2/newWatermark/MTIxMDM3MDYyNTA_zh_4.mp4'
    }
  },
  created() {
    this.getVideoList();
  },
  methods:{
    getVideoList(){//获取视频地址列表
      this.$axios.get("/api/wxmp/news/video/list",{
        params:{
          deptId:this.$store.state.hh_deptId
        }
      }).then((res) =>{
        console.log('res',JSON.stringify(res));
        if(res.code === 0) {
          this.videoList = res.data.map(item => {
            return {
              id: item.videoId,
              url: item.url,
              title:item.title,
              poster: item.coverPic,
            }
          })
        } else {
          this.videoList = []
        }
        this.initSwiper()
      }).catch((err) =>{
        console.log('err',err);
      });
    },
    showVideo(url){
      this.videoUrl = url;
      this.videoShow = true;
      //console.log('this.myPlayer',this.myPlayer);
      this.$nextTick(() =>{
        if(!this.myPlayer){
          this.myPlayer = document.getElementById('player');
          //console.log('document.getElementById(\'player\');',document.getElementById('player'));
        }else{
          this.myPlayer.play();
        }
      })

    },
    closeVideoShow(){
      this.videoShow = false;
      //console.log('this.myPlayer',this.myPlayer);
      if(this.myPlayer) {
        this.myPlayer.pause();
        //console.log('暂停....');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .video-wrapper{
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    padding: 10px;
  }
  .video-img{
    position: relative;
  }
  .video-img{
    width: 100%;
  }
  .video-img .van-image{
    height: 150px;
  }
  .video-title{
    padding: 5px 0 5px 0;
    font-size: 14px;
    height: 3em;
    color: black;
    font-weight: bolder;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .video-time{
    padding: 3px 5px;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 130px;
    background-color: #1E1916;
    opacity: .7;
    color: #fff;
  }
  .video-play{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    padding: 3px;
    background-color: #454444;
    border-radius: 100%;
    text-align: center;
  }
  .video-show-wrapper{
    width: 100%;
    //height: 100%;
    max-height: 100%;
    position: relative;
  }
  .video-close{
    border: 1px solid #888;
    height: 30px;
    width: 30px;
    line-height: 30px;
    border-radius: 100%;
    position: absolute;
    left: 10px;
    top: 10px;
  }
  .video-obj{
    width: 100%;
    height: 100%;
  }
  .video-player {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .video-item +.video-item{
    margin-top: 5px;
  }
</style>
