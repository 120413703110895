<template>
  <life-home></life-home>
</template>

<script>
import LifeHome from "@/views/ycty/LifeHome";
export default {
  components:{
    LifeHome
  },
  name: "Home2",
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>

</style>
